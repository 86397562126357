import React from "react";
import StaticPage from "../components/StaticPage";
import ArticlesList from '../components/ArticlesList'
import NewsList from '../components/NewsList'
import LabsList from '../components/LabsList'
import InterviewsList from '../components/InterviewsList'

const capitalizeFirstChar = str => str.charAt(0).toUpperCase() + str.substring(1);

const CategoryTemplate = ({ pageContext }) => {
  const { title, type, tag } = pageContext
  return (
    <StaticPage title={tag ? capitalizeFirstChar(tag) : title}>
     {type ==='articles' && <ArticlesList/>}
     {type ==='news' && <NewsList tag={tag} />}
     {type ==='interview' && <InterviewsList />}
     {type ==='labs' && <LabsList />}
    </StaticPage>
  );
};

export default CategoryTemplate;
