import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import ItemList from './ItemsList'

const NewsList = () => {
    const { items } = useStaticQuery(
        graphql`
          query {
            items: allMarkdownRemark(
              filter: { frontmatter: { layout: { eq: "labs" } } }
              sort: { fields: [frontmatter___date], order: DESC }
            ) {
              nodes {
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  thumbnail {
                    childImageSharp {
                      fluid(maxWidth: 300, maxHeight: 300) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        `
      );
    return (
        <ItemList items={items}/>
    )
}

export default NewsList
