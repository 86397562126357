import React from "react";
import { css } from "@emotion/core";
import { Link } from "gatsby";
import Img from "gatsby-image";

const gridStyle = (theme) => css`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 15px;
  row-gap: 15px;
  margin-top: 50px;

  ${theme.breakpoints.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  ${theme.breakpoints.mobile} {
    grid-template-columns: 1fr;
  }
`;

const tileStyle = css`
  text-decoration: none;
`;

const titleStyle = (theme) => css`
  ${theme.typography.h3};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-top: 5px;
  margin-bottom: 0;
  word-wrap: anywhere;
`;

const articleMetaStyle = css`
`

const authorStyle = theme => css`
    margin-top: 0;
    color: ${theme.colors.gray};
    font-size: .9em;
`

const ItemsList = ({ items }) => {
  return (
    <div css={gridStyle}>
      {items.nodes &&
        items.nodes.map((item) => (
          <Link to={item.fields.slug} key={item.id} css={tileStyle}>
            <div>
              {item.frontmatter.thumbnail && (
                <Img
                  fluid={item.frontmatter.thumbnail.childImageSharp.fluid}
                  alt=""
                />
              )}
              <div css={articleMetaStyle}>
                <h3 css={titleStyle}>{item.frontmatter.title}</h3>
                {item.frontmatter.author && <p css={authorStyle}>{item.frontmatter.author}</p>}
              </div>
            </div>
          </Link>
        ))}
    </div>
  );
};

export default ItemsList;
