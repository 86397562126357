import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import ItemList from "./ItemsList";

const NewsList = ({ tag }) => {
  const { items } = useStaticQuery(
    graphql`
      query {
        items: allMarkdownRemark(
          filter: { frontmatter: { layout: { eq: "news" } } }
          sort: { fields: [frontmatter___date], order: DESC }
        ) {
          nodes {
            id
            fields {
              slug
            }
            frontmatter {
              title
              tags
              thumbnail {
                childImageSharp {
                  fluid(maxWidth: 300, maxHeight: 300) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  const filteredItems = tag
    ? {
        nodes: items.nodes.filter((item) => {
          return item.frontmatter.tags && item.frontmatter.tags.includes(tag);
        }),
      }
    : items;
  return <ItemList items={filteredItems} />;
};

export default NewsList;
